import React, { FC } from 'react';

import { Title, Paragraph } from '../../components/Typography';
import { Wrapper } from './styles';

const AboutUs: FC<{}> = () => {
  return (
    <Wrapper>
      <Title>Who we are</Title>
      <Paragraph>
        Nimble Viz was founded by a photographer-turned-3D-artist and a
        digital-marketer-turned-software-developer. We're flexible specialized
        generalists. Our mission is to be on time and on budget. Our goal is to
        be nimble.
      </Paragraph>
    </Wrapper>
  );
};

export default AboutUs;
