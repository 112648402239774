import React, { FC } from "react"
import { Title, Paragraph } from "../../../components/Typography"
import { Wrapper, TextWrapper } from "./styles"

type ContentProps = {
  title: string
  description: string
}

const Content: FC<ContentProps> = ({ title, description }) => {
  return (
    <Wrapper>
      <TextWrapper>
        <Title>{title}</Title>
        <Paragraph>{description}</Paragraph>
      </TextWrapper>
    </Wrapper>
  )
}
export default Content
