import styled from "styled-components"

export const VideoWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: -1;
`

export const BackgroundVideo = styled.video`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`
