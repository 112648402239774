import React, { FC, useState, createRef } from 'react';
// import Recaptcha from 'react-google-recaptcha';

import {
  FormWrapper,
  Input,
  TextArea,
  Option,
  RadiusOption,
  StyledButton,
  SuccessMessage,
  ErrorMessage,
} from './styles';
import { ContentBanner } from '../ContentBanner';
import { Title } from '../Typography';

const DEFAULT_STATE = {
  NAME: '',
  EMAIL: '',
  COMPANY: '',
  HAVECAD: '',
  MESSAGE: '',
};

// const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;
// if (typeof RECAPTCHA_KEY === 'undefined') {
//   throw new Error(`
//   Env var SITE_RECAPTCHA_KEY is undefined!
//   You probably forget to set it in your Netlify build environment variables.
//   Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
//   Note this demo is specifically for Recaptcha v2
//   `);
// }

const encode = (data: any) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const ContactForm: FC<{}> = () => {
  const [info, setInfo] = useState(DEFAULT_STATE);
  const recaptchaRef = createRef() as any;

  const [message, setMessage] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    // const recaptchaValue = recaptchaRef.current.getValue();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        // 'g-recaptcha-response': recaptchaValue,
        ...info,
      }),
    })
      .then(() => {
        setSuccess(true);
        setMessage('Your message was sent!');
      })
      .catch(error => {
        setSuccess(false);
        console.error('error', error);
      });
    e.preventDefault();
  };

  const handleChange = (event: any) => {
    event.persist();
    setInfo(state => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
    setMessage('');
  };

  return (
    <ContentBanner id="contact">
      <form
        name="contact"
        method="post"
        onSubmit={handleSubmit}
        action="/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        data-netlify-recaptcha="true"
      >
        <input type="hidden" name="form-name" value="contact" />
        <Title>Get in touch!</Title>
        <FormWrapper>
          <Input
            type="text"
            value={info.NAME}
            name="NAME"
            placeholder="Name *"
            id="mce-NAME"
            pattern="[a-zA-Z ]+"
            required
            onChange={handleChange}
          />
          <Input
            type="email"
            value={info.EMAIL}
            name="EMAIL"
            placeholder="Email *"
            id="mce-EMAIL"
            pattern="^[a-zA-Z0-9-\_.]+@[a-zA-Z0-9-\_.]+\.[a-zA-Z0-9.]{2,5}$"
            required
            onChange={handleChange}
          />
          <Input
            type="text"
            value={info.COMPANY}
            name="COMPANY"
            placeholder="Company"
            id="mce-COMPANY"
            onChange={handleChange}
          />
          <RadiusOption>
            Do you have 3D CAD files for the product?
            <ul>
              <Option>
                <input
                  type="radio"
                  value="Yes"
                  name="HAVECAD"
                  id="mce-HAVECAD-0"
                  onChange={handleChange}
                />
                <label htmlFor="mce-HAVECAD-0">Yes</label>
              </Option>
              <Option>
                <input
                  type="radio"
                  value="No"
                  name="HAVECAD"
                  id="mce-HAVECAD-1"
                  onChange={handleChange}
                />
                <label htmlFor="mce-HAVECAD-1">No</label>
              </Option>
            </ul>
          </RadiusOption>
          <TextArea
            value={info.MESSAGE}
            name="MESSAGE"
            placeholder="Message *"
            id="mce-MESSAGE"
            required
            rows={5}
            onChange={handleChange}
          />
          {/* <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} /> */}
          <StyledButton type="submit">Send</StyledButton>
        </FormWrapper>
      </form>
      {success ? (
        <SuccessMessage>{message}</SuccessMessage>
      ) : (
        <ErrorMessage>{message}</ErrorMessage>
      )}
    </ContentBanner>
  );
};

export default ContactForm;
