import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FluidImg } from './styles';

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 400, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
const Image: FC<{ img: string }> = ({ img }) => {
  const data = useStaticQuery(graphql`
    query {
      detail: file(relativePath: { eq: "images/detail.jpg" }) {
        ...squareImage
      }
      drama: file(relativePath: { eq: "images/drama.jpg" }) {
        ...squareImage
      }
      gravity: file(relativePath: { eq: "images/gravity.jpg" }) {
        ...squareImage
      }
      options: file(relativePath: { eq: "images/options.jpg" }) {
        ...squareImage
      }
      closeup: file(relativePath: { eq: "images/closeup.jpg" }) {
        ...squareImage
      }
      real: file(relativePath: { eq: "images/real.jpg" }) {
        ...squareImage
      }
      function: file(relativePath: { eq: "images/function.jpg" }) {
        ...squareImage
      }
      studio: file(relativePath: { eq: "images/studio.jpg" }) {
        ...squareImage
      }
      virtual: file(relativePath: { eq: "images/virtual.jpg" }) {
        ...squareImage
      }
      hot: file(relativePath: { eq: "images/hot.jpg" }) {
        ...squareImage
      }
      water: file(relativePath: { eq: "images/water.jpg" }) {
        ...squareImage
      }
    }
  `);
  return (
    <FluidImg
      fluid={data[img].childImageSharp.fluid}
      alt={`Nimbleviz-${img}`}
    />
  );
};

export default Image;
