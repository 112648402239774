import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Banner from '../containers/Banner';
import DescSection from '../containers/DescSection';
import Gallery from '../containers/Gallery';
import Examples from '../containers/Examples';
import AboutUs from '../containers/AboutUs';
import ContactForm from '../components/ContactForm';

const IndexPage = () => (
  <Layout>
    <SEO />
    <Banner />
    <DescSection />
    <Gallery />
    <Examples />
    <AboutUs />
    <ContactForm />
  </Layout>
);

export default IndexPage;
