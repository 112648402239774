import React, { FC } from 'react';

import Items from './Items';

import { H2 } from '../../components/Typography';
import { Wrapper } from './styles';

const Examples: FC<{}> = () => {
  return (
    <Wrapper>
      <H2>3D Product Animation is perfect for</H2>
      <Items />
    </Wrapper>
  );
};

export default Examples;
