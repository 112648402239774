import styled from "styled-components"

import { device } from "../../helpers/media-query"

export const Wrapper = styled.div`
  display: block;
  height: calc(100vh - 85px);
  background: #25252552;
`

export const TextWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;

  @media ${device.tablet} {
    text-align: center;
  }
`
