import React, { FC } from 'react';

import Icon from './Icon';

import { ItemWrapper, ItemText, Title, Desc, Center } from './styles';
import { items } from './utils';

const Items: FC<{}> = () => (
  <>
    {items.map((item, i) => (
      <Center key={i}>
        <ItemWrapper>
          <Icon type={item.icon} />
          <ItemText>
            <Title>{item.title}</Title>
            <Desc>{item.description}</Desc>
          </ItemText>
        </ItemWrapper>
      </Center>
    ))}
  </>
);

export default Items;
