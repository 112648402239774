import styled from 'styled-components';
import { FluidImg } from './Image/styles';

export const Card = styled.div`
  display: flex;
  max-width: 1600px;
  margin: 0 auto 40px;

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }

  &:hover {
    ${FluidImg} {
      transform: translateY(-2px);
      box-shadow: 0 50px 80px -20px rgba(0, 0, 0, 0.27),
        0 30px 50px -30px rgba(0, 0, 0, 0.3);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;

    &:nth-child(odd) {
      flex-direction: column-reverse;
    }
  }
`;
