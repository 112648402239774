export const items = [
  {
    title: 'Product marketing and advertising',
    description:
      'Create feature-film quality ads to launch and sell your products.',
    icon: 'marketing',
  },
  {
    title: 'Crowdsourced Fundraising',
    description:
      'Use sophisticated 3D animations to attract attention and donations to your crowdfunded projects on Kickstarter, Indiegogo, and other platforms.',
    icon: 'crowdfunding',
  },
  {
    title: 'Trade Shows',
    description:
      'Use premium 3D animations to attract viewers and impress people at trade shows.',
    icon: 'tradeshows',
  },
  {
    title: 'Tecnical Explainers',
    description: 'Show how your product works and what it is made of.',
    icon: 'technical',
  },
  {
    title: 'Industrial Animation',
    description: 'Show how your product is made, no matter the scale.',
    icon: 'industrial',
  },
  {
    title: 'Social Media Content',
    description:
      'Fill your social media presence with blockbuster production value content.',
    icon: 'social',
  },
];
