import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { StyledIcon } from './styles';

export const squareIcon = graphql`
  fragment squareIcon on File {
    childImageSharp {
      fixed(width: 60, height: 60) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;
const Icon: FC<{ type: string }> = ({ type }) => {
  const data = useStaticQuery(graphql`
    query {
      marketing: file(relativePath: { eq: "images/product-marketing.png" }) {
        ...squareIcon
      }
      crowdfunding: file(relativePath: { eq: "images/crowdfunding.png" }) {
        ...squareIcon
      }
      tradeshows: file(relativePath: { eq: "images/trade-shows.png" }) {
        ...squareIcon
      }
      technical: file(relativePath: { eq: "images/technical-explainers.png" }) {
        ...squareIcon
      }
      industrial: file(
        relativePath: { eq: "images/industrial-animation.png" }
      ) {
        ...squareIcon
      }
      social: file(relativePath: { eq: "images/socialmedia-content.png" }) {
        ...squareIcon
      }
    }
  `);
  return (
    <StyledIcon
      fixed={data[type]?.childImageSharp.fixed}
      alt={`Nimbleviz-${type}`}
    />
  );
};

export default Icon;
