import React, { FC } from "react"

import Video from "./Video"

import { Paragraph, H1 } from "../../components/Typography"
import { Wrapper, TextWrapper } from "./styles"

const Banner: FC<{}> = () => {
  return (
    <Wrapper>
      <TextWrapper>
        <H1>High-end 3D product animation</H1>
        <Paragraph>Photorealistic images to market your products</Paragraph>
      </TextWrapper>
      <Video />
    </Wrapper>
  )
}

export default Banner
